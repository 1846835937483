import { render } from "lit-html"

import { BING_MAPS_KEY, US_STATES_TO_ABBREV } from "../constants"

import { AutocompleteText } from "./autocomplete-text"

export const GEOCODE_ENDPOINT = `https://dev.virtualearth.net/REST/v1/Autosuggest?key=${BING_MAPS_KEY}&ul=41.876,-87.65&query=`

export const onResults = (res) =>
  res.resourceSets[0].resources[0].value.map((r) => ({
    ...r.address,
    value: r.address.addressLine || r.address.formattedAddress,
    label: r.address.formattedAddress,
  }))

const US_STATE_ABBREV_LIST = Object.values(US_STATES_TO_ABBREV)
export const getStateAbbrev = (state) => {
  // "state" can be "California" or "CA"
  return (
    US_STATES_TO_ABBREV[state] ||
    US_STATE_ABBREV_LIST.find((abbrev) => abbrev === state) ||
    ""
  )
}

export default function GeocodeInput(el, parentForm = null) {
  const mount = el.parentNode

  render(
    AutocompleteText({
      name: el.name,
      value: el.value || ``,
      required: el.hasAttribute("required"),
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
      endpoint: GEOCODE_ENDPOINT,
      onResults,
      onSelect: (item) => {
        const queryContext = parentForm || document
        const cityInput = queryContext.querySelector(`input[name="city"]`)
        const zipInput = queryContext.querySelector(`input[name="zip_code"]`)
        const stateSelect = queryContext.querySelector(`select[name="state"]`)
        if (cityInput) cityInput.value = item.locality || ""
        if (stateSelect) stateSelect.value = getStateAbbrev(item.adminDistrict)
        if (zipInput) zipInput.value = item.postalCode || ""
      },
    }),
    mount
  )
}
