import { html } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { TYPES_COLOR_MAP } from "../constants"

const TypesList = (types) => html`
  ${repeat(
    types,
    (type) => type,
    (type, idx) => html`
      <span
        class="inline-block text-tag-text-${type in TYPES_COLOR_MAP
          ? TYPES_COLOR_MAP[type]
          : "green"}"
      >
        ${type}${idx < types.length - 1 ? html` ,&nbsp; ` : ""}
      </span>
    `
  )}
`

export default TypesList
