import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useState } from "haunted"

const CheckboxOtherSpecify = virtual(({ name, startIndex }) => {
  const [otherValue, setOtherValue] = useState(``)
  const [options, setOptions] = useState([])

  const onAdd = () => {
    setOptions([...options, { option: otherValue, checked: true }])
    setOtherValue(``)
  }

  return html`
    ${repeat(
      options,
      ({ option }) => option,
      ({ option, checked }, index) => html`
        <label class="label checkbox" for="id_${name}_${startIndex + index}">
          <input
            id="id_${name}_${startIndex + index}"
            type="checkbox"
            name=${name}
            value=${option}
            .checked=${checked}
          />
          <span tabindex="-1">${option}</span>
        </label>
      `
    )}
    <div class="other-specify-field flex">
      <input
        type="text"
        class="input"
        aria-label="Other (type to specify)"
        placeholder="Other (type to specify)"
        .value=${otherValue}
        @input=${(e) => setOtherValue(e.target.value)}
        @change=${(e) => setOtherValue(e.target.value)}
        @keydown=${(e) => {
          if (e.code === "Enter") {
            e.preventDefault()
            onAdd()
          }
        }}
      />
      <button
        type="button"
        class="button text-blue text-red-active min-w-40 w-10 ml-4"
        aria-label="Add option"
        @click=${onAdd}
        ?disabled=${!otherValue || otherValue.trim() === ""}
      >
        <i class="cb fa-plus"></i>
      </button>
    </div>
  `
})

export default function renderCheckboxOtherSpecify(el) {
  const otherCheckbox = el.querySelector("[value='Other']")

  if (!otherCheckbox) {
    return
  }

  // Replace "Other" checkbox with a new container to mount lit-html on
  const container = otherCheckbox.parentElement.parentElement
  container.removeChild(otherCheckbox.parentElement)
  const mount = document.createElement("div")
  container.appendChild(mount)

  const splitId = otherCheckbox.id.split("_")
  const name = splitId.slice(1, -1).join("_")
  const startIndex = +splitId.slice(-1)[0]

  render(CheckboxOtherSpecify({ name, startIndex }), mount)
  mount.replaceWith(...mount.childNodes)
}
