import {
  ROLE_ASSIGN_PATH,
  EDIT_PATH,
  CLONE_PATH,
  DELETE_PATH,
  CALENDAR_PATH,
  CREATE_ASSIGNMENT_PATH,
  CREATE_ASSIGNMENT_MEETING_PARAM,
  PROFILE_PATH,
  CANCEL_PATH,
  REGISTER_PATH,
  DISCOURSE_PATH,
  NOTIFICATIONS_BASE_PATH,
  NOTIFICATIONS_MARK_READ_PATH,
  NOTIFICATIONS_UNREAD_PATH,
  NOTIFICATIONS_MARK_ALL_READ_PATH,
} from "./paths"

export {
  ROLE_ASSIGN_PATH,
  EDIT_PATH,
  CLONE_PATH,
  DELETE_PATH,
  CALENDAR_PATH,
  CREATE_ASSIGNMENT_PATH,
  CREATE_ASSIGNMENT_MEETING_PARAM,
  PROFILE_PATH,
  CANCEL_PATH,
  REGISTER_PATH,
  DISCOURSE_PATH,
  NOTIFICATIONS_BASE_PATH,
  NOTIFICATIONS_MARK_READ_PATH,
  NOTIFICATIONS_UNREAD_PATH,
  NOTIFICATIONS_MARK_ALL_READ_PATH,
}

export const BING_MAPS_KEY =
  "AiegnVCmsj1OZ2sd5TA9qD3ac52O7o333_PeGrmuchdls9idp6EecluLfgtEqFda"

export const AZURE_MAPS_KEY = "h3Ls6TVbXyE659bLwqB_NY5smmRaxpFEqO4RDq_nzB4"

export const DEBOUNCE_TIME = 500

export const MENU_ACTION_KEYS = [
  "ArrowDown",
  "ArrowUp",
  "ArrowLeft",
  "ArrowRight",
  "Space",
]

export const ANY_AVAILABLE = "Any available"

export const ALT_SORT = "alt-sort"

export const TYPES_COLOR_MAP = {
  Orientation: "green",
  Training: "red",
  Webchat: "brown",
  "Office Hours": "green",
  Special: "yellow",
  "Audio Recording": "red",
  "Video Recording": "yellow",
  Interviewing: "red",
  "Live-tweeting": "blue",
  Notetaking: "green",
  "Web Research": "blue",
  "Mobile Photography": "green",
  "Open Meeting Law": "red",
  "Community of Practice": "green",
}

export const ROLE_ICON_MAP = {
  Audio: "fas fa-microphone",
  Notes: "fas fa-pen-alt",
  Notetaking: "fas fa-pen-alt",
  Interview: "fas fa-user-friends",
  Photos: "fas fa-camera",
  "Special assignment": "fas fa-star",
  "Street team": "fas fa-user-friends",
  "Live-tweeting": "fab fa-twitter",
  Video: "fas fa-video",
  "Any available": "fas fa-dice",
}

export const MAX_DOCS = 4

export const DOC_ICON_MAP = {
  Audio: "fas fa-microphone",
  Notes: "fas fa-pen-alt",
  "Google Drive": "fas fa-pen-alt",
  "Google Doc": "fas fa-pen-alt",
  "Google Docs": "fas fa-pen-alt",
  Image: "far fa-image",
  "Live-tweeting": "fab fa-twitter",
  Twitter: "fab fa-twitter",
  "Twitter thread": "fab fa-twitter",
  "Document Cloud": "fas fa-file",
  PDF: "fas fa-file-pdf",
  Video: "fas fa-video",
  File: "fas fa-file",
  URL: "fas fa-link",
  Notetaking: "fas fa-pen-alt",
  Interview: "fas fa-user-friends",
  Photos: "fas fa-camera",
  "Special assignment": "fas fa-star",
  "Any available": "fas fa-dice",
}

export const ACTOR_SUB = "<ACTOR>"

export const TARGET_SUB = "<TARGET>"

export const ACTOR_ASSIGNMENT_SUB = "<ACTOR_ASSIGNMENT>"

export const TARGET_ASSIGNMENT_SUB = "<TARGET_ASSIGNMENT>"

export const ASSIGNMENT_DRAFT = "Draft"

export const ASSIGNMENT_OPEN = "Open"

export const ASSIGNMENT_ASSIGNED = "Assigned"

export const USER_INITIAL_COLORS = ["yellow", "red", "brown", "blue", "green"]

export const US_STATES_TO_ABBREV = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
}

export const ERROR_MESSAGE = "There was an error handling your request."
