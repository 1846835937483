export const ROLE_ASSIGN_PATH = `assign/`

export const EDIT_PATH = `edit/`

export const CLONE_PATH = `clone/`

export const DELETE_PATH = `delete/`

export const CALENDAR_PATH = `calendar/`

export const CREATE_ASSIGNMENT_PATH = `/assignments/create/`

export const CREATE_ASSIGNMENT_MEETING_PARAM = `meeting_pk`

export const PROFILE_PATH = `profile/`

export const CANCEL_PATH = `cancel/`

export const REGISTER_PATH = `register/`

export const DISCOURSE_PATH = `/discourse/notifications`

export const NOTIFICATIONS_BASE_PATH = `/notifications/`

export const NOTIFICATIONS_MARK_READ_PATH = `mark-read/`

export const NOTIFICATIONS_UNREAD_PATH = `${NOTIFICATIONS_BASE_PATH}api/unread/`

export const NOTIFICATIONS_MARK_ALL_READ_PATH = `${NOTIFICATIONS_BASE_PATH}mark-all-read/`
