import { html, render } from "lit-html"
import { virtual, useEffect, useState } from "haunted"

const RadioSelectOtherSpecify = virtual(
  ({ id, name, value, checked, disabled }) => {
    const defaultInputValue = value !== "Other" ? value : ""
    const [otherInputValue, setOtherInputValue] = useState(defaultInputValue)
    const [otherValue, setOtherValue] = useState(value)

    useEffect(() => {
      setOtherValue(otherInputValue || "Other")
    }, [otherInputValue])

    return html`
      <label class="radio" for="${id}">
        <input
          type="radio"
          class="single-other-specify-input"
          name="${name}"
          id="${id}"
          .value="${otherValue}"
          ?checked=${checked}
          ?disabled=${disabled}
        />
        <span tabindex="-1"> Other </span>
        <div
          class="other-specify-field mt-6 w-full min-w-300 flex items-center"
        >
          <input
            type="text"
            class="input custom-reset-input"
            aria-label="Type to specify"
            placeholder="Type to specify"
            value="${defaultInputValue}"
            .value="${otherInputValue}"
            ?disabled=${disabled}
            @input=${(e) => setOtherInputValue(e.target.value)}
            @change=${(e) => setOtherInputValue(e.target.value)}
            @keydown=${(e) => {
              if (e.code === "Enter") {
                e.preventDefault()
              }
            }}
            @reset=${() => setOtherInputValue(defaultInputValue)}
          />
          ${disabled
            ? ``
            : html`<span
                class="ml-8 cursor-pointer"
                @click="${() => setOtherInputValue("")}}"
              >
                <i class="fa fa-trash"></i>
              </span>`}
        </div>
      </label>
    `
  }
)

export default function renderRadioSelectOtherSpecify(el) {
  const $otherOptionLabel = el.querySelector("label.radio:last-child")
  if (!$otherOptionLabel) return

  const $otherOption = $otherOptionLabel.querySelector("input")
  if (!$otherOption) return

  // Replace "Other" checkbox with a new container to mount lit-html on
  const container = $otherOption.parentElement.parentElement
  container.removeChild($otherOption.parentElement)
  const mount = document.createElement("div")
  container.appendChild(mount)

  const otherOptionData = {
    id: $otherOption.id,
    name: $otherOption.name,
    value: $otherOption.value,
    checked: $otherOption.checked,
    disabled: $otherOption.disabled,
  }
  render(RadioSelectOtherSpecify(otherOptionData), mount)
  mount.replaceWith(...mount.childNodes)
}
