import { html, render } from "lit-html"

import { AutocompleteSelect } from "./autocomplete-select"
import { fontAwesomeIcons } from "../icons/index.js"

const FaIconSelect = ({ name, value }) => {
  const options = fontAwesomeIcons.map((icon) => {
    const iconCls = `${icon.prefix} fa-${icon.iconName}`

    return {
      label: iconCls,
      value: iconCls,
      selected: iconCls === value,
    }
  })
  return AutocompleteSelect({
    options: [
      {
        label: "----------------------------",
        value: "",
        selected: value === "",
      },
      ...options,
    ],
    name,
    placeholder: "Select your badge icon",
    labelFn: ({ label, value }) =>
      html`<i class="${value}" style="width:12px;"></i> ${label}`,
  })
}

export default function renderFaIconSelect($el) {
  const mount = document.createElement("div")
  render(FaIconSelect({ name: $el.name, value: $el.value }), mount)
  $el.replaceWith(...mount.childNodes)
}
