import { html } from "lit-html"
import { useEffect, useRef, useState } from "haunted"
import { ref } from "../directives"

export const SelectBox = ({
  width = "175px",
  options,
  placeholder = null,
  resetOption = null,
  selectedOption = null,
}) => {
  const fieldRef = useRef(null)
  const [currentOptionLabel, setCurrentOptionLabel] = useState(
    placeholder || options[0].label
  )
  const [showOptions, setShowOptions] = useState(false)

  const onSelectOption = (e, option) => {
    e.preventDefault()

    const { label, onClick } = option
    if (label != currentOptionLabel) {
      setCurrentOptionLabel(label)
      onClick()
      setShowOptions(false)
    }
  }

  const onClickSelectBox = () => {
    setShowOptions(!showOptions)
    fieldRef.current.querySelector("button").focus()
  }

  const onBlurSelectBoxButton = (e) => {
    const clickedWithinDropdown =
      fieldRef &&
      !!e.relatedTarget &&
      fieldRef.current.contains(e.relatedTarget)
    if (!clickedWithinDropdown) {
      setShowOptions(false)
    } else {
      fieldRef.current.querySelector("button").focus()
    }
  }

  const onReset = (e) => {
    e.preventDefault()

    setCurrentOptionLabel(placeholder)
    resetOption.onClick()
    setShowOptions(false)
  }

  const resetOptionEl =
    resetOption && currentOptionLabel !== placeholder
      ? html`<a
          href="#"
          @click="${onReset}"
          class="block px-6 py-3 bg-white text-red font-bold hover:bg-yellow-rowhighlight hover:text-red focus:bg-yellow-rowhighlight"
          role="menuitem"
          tabindex="-1"
          >${resetOption.label}</a
        >`
      : html``
  const buttonClass =
    currentOptionLabel === placeholder ? "italic text-gray-interactive" : ""

  useEffect(() => {
    if (selectedOption) {
      onSelectOption(new CustomEvent("click"), selectedOption)
    }
  }, [])

  return html`
    <div
      class="relative inline-block text-left text-sm"
      style="width: ${width};"
      ?ref=${ref(fieldRef)}
    >
      <div
        @click="${onClickSelectBox}"
        class="w-full border-px border-gray-border rounded flex flex-row justify-between bg-gray-bg px-6 py-5 font-sm text-black cursor-pointer"
      >
        <button
          type="button"
          @blur="${onBlurSelectBoxButton}"
          class="inline-flex justify-center focus:outline-none ${buttonClass}"
          aria-expanded="true"
          aria-haspopup="true"
        >
          ${currentOptionLabel}
        </button>
        <a class="inline-flex items-center">
          <i class="fas fa-chevron-down text-gray-interactive"></i>
        </a>
      </div>

      <div
        class="${showOptions
          ? ""
          : "hidden"} z-10 w-full absolute py-5 border-px border-gray-border shadow-select-box rounded bg-white"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
      >
        <div class="py-1" role="none">
          ${resetOptionEl}
          ${options.map((option) => {
            const selectedClass =
              option.label === currentOptionLabel
                ? "text-blue hover:text-blue"
                : "text-black hover:text-black"
            return html`<a
              href="#"
              @click="${(e) => onSelectOption(e, option)}"
              class="block px-6 py-3 bg-white hover:bg-yellow-rowhighlight focus:bg-yellow-rowhighlight ${selectedClass}"
              role="menuitem"
              tabindex="-1"
              >${option.label}</a
            >`
          })}
        </div>
      </div>
    </div>
  `
}
