export const prefix = "far"
export const iconName = "circle-minus"
export const width = 18
export const height = 18
export const ligatures = []
export const unicode = "f056"
export const svgPathData =
  "M4.83268 8.16699V9.83366H13.166V8.16699H4.83268ZM8.99935 0.666992C4.39935 0.666992 0.666016 4.40033 0.666016 9.00033C0.666016 13.6003 4.39935 17.3337 8.99935 17.3337C13.5993 17.3337 17.3327 13.6003 17.3327 9.00033C17.3327 4.40033 13.5993 0.666992 8.99935 0.666992ZM8.99935 15.667C5.32435 15.667 2.33268 12.6753 2.33268 9.00033C2.33268 5.32533 5.32435 2.33366 8.99935 2.33366C12.6743 2.33366 15.666 5.32533 15.666 9.00033C15.666 12.6753 12.6743 15.667 8.99935 15.667Z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const farCircleMinus = definition
