import snarkdown from "snarkdown"
import { sanitizeText } from "../utils"
import { html } from "lit-html"
import { unsafeHTML } from "lit-html/directives/unsafe-html"

// We're using a more limited markdown parser that doesn't include paragraph tags, so
// we're implementing our own splitting on 2 newlines here
export default function (md) {
  return md
    .split("\n\n")
    .filter((line) => !!line.trim())
    .map((line) => html`<p>${unsafeHTML(snarkdown(sanitizeText(line)))}</p>`)
}
