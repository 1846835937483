import { html, nothing, render } from "lit-html"
import { virtual, useState, useEffect } from "haunted"
import { repeat } from "lit-html/directives/repeat"

const DAILY = "Daily"
const WEEKLY = "Weekly"
const MONTHLY = "Monthly"

const RECURRING_WEEKDAY_CHOICES = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
}

const RECURRING_WEEK_OF_MONTH_CHOICES = {
  0: "first",
  1: "second",
  2: "third",
  3: "fourth",
  "-1": "last",
}

const MONTHLY_BY_DAY = "by_day"
const MONTHLY_BY_WEEK = "by_week"

const DEFAULT_PATTERN = {
  separation_count: 2,
  recurring_type: WEEKLY,
  day_of_week: 0,
  week_of_month: 0,
  day_of_month: 1,
  end_date: null,
}

const RecurringPatternForm = virtual(
  ({ index, name, key, pattern, onUpdatePattern, onRemovePattern }) => {
    const today = new Date().toISOString().split("T")[0]
    const [inputPatterns, setInputPatterns] = useState(pattern)
    const {
      separation_count: separationCount,
      day_of_month: dayOfMonth,
    } = pattern
    const dayOfWeek = `${pattern.day_of_week}`
    const weekOfMonth = `${pattern.week_of_month}`

    const [recurringType, setRecurringType] = useState(pattern.recurring_type)

    useEffect(() => {
      if (recurringType === DAILY) {
        setFormFieldValue("day_of_week", null)
        setFormFieldValue("week_of_month", null)
        setFormFieldValue("day_of_month", null)
      }
      if (recurringType === WEEKLY) {
        setFormFieldValue("week_of_month", null)
        setFormFieldValue("day_of_month", null)
      }
    }, [recurringType])

    useEffect(() => {
      onUpdatePattern(key, { ...inputPatterns })
    }, [inputPatterns])

    const setFormFieldValue = (field, value) => {
      setInputPatterns({
        ...inputPatterns,
        [field]: value,
      })
    }

    const onChangeRecurringType = (value) => {
      setRecurringType(value)
      setFormFieldValue("recurring_type", value)
    }
    const [monthlyRecurrence, setMonthlyRecurrence] = useState(
      dayOfMonth ? MONTHLY_BY_DAY : MONTHLY_BY_WEEK
    )
    const [hasEndDate, setHasEndDate] = useState(!!pattern.end_date)
    const [endDate, setEndDate] = useState(pattern.end_date)
    const [endDateErrMessage, setEndDateErrMessage] = useState("")

    const validateEndDate = (date) => {
      if (!date || date < today) {
        setEndDateErrMessage("Please enter a valid end date")
      } else {
        setEndDateErrMessage("")
      }
    }

    const onChangeMonthlyRecurrence = (monthlyBy) => {
      setMonthlyRecurrence(monthlyBy)
      if (monthlyBy === MONTHLY_BY_WEEK) {
        setFormFieldValue("day_of_month", null)
      }
      if (monthlyBy === MONTHLY_BY_DAY) {
        if (!pattern.day_of_month) {
          setFormFieldValue("day_of_month", 1)
        }
      }
    }

    const onChangeEndDate = (value) => {
      const date = value === "" ? null : value
      validateEndDate(date)
      setEndDate(date)
      setFormFieldValue("end_date", date)
    }

    const onChangeHasEndDate = (withEndDate) => {
      const date = withEndDate ? endDate : null
      validateEndDate(date)
      setFormFieldValue("end_date", date)
      setHasEndDate(withEndDate)
      if (!withEndDate) {
        setEndDateErrMessage(``)
      }
    }

    const repeatEveryHtml = html`
      <span class="mr-5 text-body text-tiny">Repeat every</span>
      <div class="flex flex-wrap items-center">
        <input
          type="number"
          class="mr-5 my-2 w-12 h-10 border-gray-border text-center"
          min="1"
          .value=${separationCount}
          @change=${(e) =>
            setFormFieldValue("separation_count", parseInt(e.target.value))}
        />
        <select
          class="my-2 w-15 h-10 border-gray-border px-6"
          @change=${(e) => {
            onChangeRecurringType(e.target.value)
          }}
        >
          <option value=${DAILY} ?selected=${recurringType === DAILY}>
            days
          </option>
          <option value=${WEEKLY} ?selected=${recurringType === WEEKLY}>
            weeks
          </option>
          <option value=${MONTHLY} ?selected=${recurringType === MONTHLY}>
            months
          </option>
        </select>
      </div>
    `

    const onWeeklyHtml = html`
      <div>
        <span class="mr-5 text-body text-tiny">On</span>
        <div class="flex items-center">
          <select
            class="day-of-week mr-5 my-2 w-15 h-10 border-gray-border px-6"
            @change=${(e) =>
              setFormFieldValue("day_of_week", parseInt(e.target.value))}
          >
            ${repeat(
              Object.entries(RECURRING_WEEKDAY_CHOICES),
              (entry) => entry[0],
              ([value, label]) => html`
                <option value=${value} ?selected=${dayOfWeek === value}>
                  ${label}
                </option>
              `
            )}
          </select>
        </div>
      </div>
    `

    const onMonthlyHtml = html`
      <div>
        <span class="text-body text-tiny">On</span>
        <div class="flex items-center">
          <input
            type="radio"
            style="margin-right:10px"
            name="${key}_monthly_recurrence"
            form="${key}_nested_form"
            value="${MONTHLY_BY_DAY}"
            ?checked=${monthlyRecurrence === MONTHLY_BY_DAY}
            @change=${() => onChangeMonthlyRecurrence(MONTHLY_BY_DAY)}
          />
          <div class="flex flex-wrap items-center">
            <span class="mr-5">Day</span>
            <input
              type="number"
              class="mr-5 my-2 w-12 h-10 border-gray-border text-center"
              min="1"
              max="31"
              ?disabled=${monthlyRecurrence !== MONTHLY_BY_DAY}
              .value=${dayOfMonth || 1}
              @change=${(e) =>
                setFormFieldValue("day_of_month", parseInt(e.target.value))}
            />
            <span>of the month</span>
          </div>
        </div>
        <div class="mt-3 flex items-center">
          <input
            type="radio"
            style="margin-right:10px"
            name="${key}_monthly_recurrence"
            form="${key}_nested_form"
            value="${MONTHLY_BY_WEEK}"
            ?checked=${monthlyRecurrence === MONTHLY_BY_WEEK}
            @change=${() => onChangeMonthlyRecurrence(MONTHLY_BY_WEEK)}
          />
          <div class="flex flex-wrap items-center">
            <select
              class="day-of-week mr-5 my-2 w-15 h-10 border-gray-border px-6"
              ?disabled=${monthlyRecurrence !== MONTHLY_BY_WEEK}
              @change=${(e) =>
                setFormFieldValue("day_of_week", parseInt(e.target.value))}
            >
              ${repeat(
                Object.entries(RECURRING_WEEKDAY_CHOICES),
                (entry) => entry[0],
                ([value, label]) => html`
                  <option value=${value} ?selected=${dayOfWeek === value}>
                    ${label}
                  </option>
                `
              )}
            </select>
            <span class="mr-5">of the</span>
            <select
              class="mr-5 my-2 w-15 h-10 border-gray-border px-6"
              ?disabled=${monthlyRecurrence !== MONTHLY_BY_WEEK}
              @change=${(e) =>
                setFormFieldValue("week_of_month", parseInt(e.target.value))}
            >
              ${repeat(
                Object.entries(RECURRING_WEEK_OF_MONTH_CHOICES),
                (entry) => entry[0],
                ([value, label]) => html`
                  <option value=${value} ?selected=${weekOfMonth === value}>
                    ${label}
                  </option>
                `
              )}
            </select>
            <span>week</span>
          </div>
        </div>
      </div>
    `

    const endDateHtml = html`
      <div class="mt-6">
        <span class="text-body text-tiny">Ends</span>
        <div class="flex items-center">
          <input
            type="radio"
            style="margin-right:10px"
            value="no-end-date"
            name="${key}_end_date"
            form="${key}_nested_form"
            ?checked=${hasEndDate === false}
            @change=${() => onChangeHasEndDate(false)}
          />
          <span>Never</span>
          <input
            type="radio"
            style="margin-left: 30px; margin-right:10px"
            value="by-end-date"
            name="${key}_end_date"
            form="${key}_nested_form"
            ?checked=${hasEndDate}
            @change=${() => onChangeHasEndDate(true)}
          />
          <span class="mr-5">On</span>
          <input
            type="date"
            form="${key}_nested_form"
            class="mr-5 my-2 w-200 h-10 border-gray-border"
            min=${today}
            ?disabled=${!hasEndDate}
            .value=${endDate}
            @change=${(e) => onChangeEndDate(e.target.value)}
          />
          <span
            class="block mt-2 text-red text-sm field-error ${endDateErrMessage
              ? ""
              : "hidden"}"
          >
            ${endDateErrMessage}
          </span>
        </div>
      </div>
    `
    return html`
      <div class="field-wrapper">
        ${index === 0
          ? html`<div class="w-full flex justify-end">
              <div
                class="w-full md:w-3/4 mt-4 border-t-px border-gray-border"
              ></div>
            </div>`
          : nothing}
        <div class="pt-6 flex justify-between items-center">
          <label
            class="mt-2 md:mb-0 w-full md:w-1/4 md:pr-4 text-gray-450 text-sm"
          >
            RECURRING PATTERN #${index + 1}
          </label>
          <a
            class="shrink-0 text-red cursor-pointer"
            @click="${onRemovePattern}"
          >
            <i class="mr-3 far fa-circle-minus"></i>
            <span class="font-bold">Remove</span>
          </a>
        </div>
        <div class="w-full flex justify-end">
          <div
            class="w-full md:w-3/4 text-black border-gray-border border-b-px py-6"
          >
            <input
              name="${name}"
              type="hidden"
              .value="${JSON.stringify(inputPatterns)}"
            />
            <div class="flex flex-column">
              <div>${repeatEveryHtml}</div>
              <div class="ml-10">
                ${recurringType === WEEKLY ? onWeeklyHtml : nothing}
                ${recurringType === MONTHLY ? onMonthlyHtml : nothing}
              </div>
            </div>
            ${endDateHtml}
          </div>
        </div>
      </div>
    `
  }
)

const RecurringPatternsWrapper = virtual(({ name, currentPatterns }) => {
  let counter = 0
  const [patterns, setPatterns] = useState(
    currentPatterns.length
      ? Object.fromEntries(
          currentPatterns.map((p) => [`key-${Date.now()}-${counter++}`, p])
        )
      : { [`key-${Date.now()}-${counter++}`]: DEFAULT_PATTERN }
  )

  const onAddPattern = () => {
    setPatterns({
      ...patterns,
      [`key-${Date.now()}-${counter++}`]: DEFAULT_PATTERN,
    })
  }

  const onRemovePattern = (key) => {
    const newPatterns = { ...patterns }
    delete newPatterns[key]
    setPatterns(newPatterns)
  }

  const onUpdatePattern = (key, pattern) => {
    setPatterns({
      ...patterns,
      [key]: pattern,
    })
  }

  return html`
    <div class="recurring-patterns">
      ${repeat(
        Object.entries(patterns),
        ([key, _]) => key, // eslint-disable-line no-unused-vars
        ([key, pattern], index) => html`
          ${RecurringPatternForm({
            index,
            name,
            key,
            pattern,
            onUpdatePattern,
            onRemovePattern: () => {
              onRemovePattern(key)
            },
          })}
        `
      )}
      <a
        @click=${onAddPattern}
        class="mt-6 block border-b-px border-gray-border pb-6 cursor-pointer"
      >
        <i class="mr-3 fas fa-plus"></i>
        <span class="font-bold">Add another pattern</span>
      </a>
    </div>
  `
})

export default function renderRecurringPatterns(el) {
  const mount = document.createElement("div")
  const currentPatterns = [...el.querySelectorAll("option")].map((option) =>
    JSON.parse(option.value)
  )
  render(
    RecurringPatternsWrapper({
      currentPatterns,
      name: el.name,
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
    }),
    mount
  )
  el.replaceWith(...mount.childNodes)
}
