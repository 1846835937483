export const prefix = "cb"
export const iconName = "plus"
export const width = 8
export const height = 9
export const ligatures = []
export const unicode = "f067"
export const svgPathData =
  "M2.941 5.227H0V3.193h2.941V0H5.06v3.193H8v2.034H5.059V8.42H2.94z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const cbPlus = definition
