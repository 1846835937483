export const prefix = "fas"
export const iconName = "arrow-down-to-line"
export const width = 12
export const height = 14
export const ligatures = []
export const unicode = "f33d"
export const svgPathData =
  "M11.8332 5.5H8.49984V0.5H3.49984V5.5H0.166504L5.99984 11.3333L11.8332 5.5ZM0.166504 13V14.6667H11.8332V13H0.166504Z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const fasArrowDownToLine = definition
