import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useEffect, useState, useRef } from "haunted"
import { ref } from "../directives"

const populateInputLabel = (selected, selectOptions, modelPlural) => {
  const selectedLength = selected.length
  if (!selectedLength) {
    return ``
  } else if (selectedLength == 1) {
    return selected[0].label
  } else if (selectedLength < selectOptions.length) {
    return `${selectedLength} ${modelPlural}`
  } else {
    return `All ${modelPlural}`
  }
}

const addOptions = (selected, addedOptions) => {
  const selectedValues = selected.map(({ value }) => value)
  const newSelectedOptions = addedOptions.filter(
    ({ value }) => !selectedValues.includes(value)
  )
  return [...selected, ...newSelectedOptions]
}

const removeOptions = (selected, removedOptions) => {
  const unselectedValues = removedOptions.map(({ value }) => value)
  return selected.filter(({ value }) => !unselectedValues.includes(value))
}

export const EmbedAutocompleteSelect = virtual(
  ({ name, required, placeholder, modelPlural, options }) => {
    const containerRef = useRef(null)
    const [didMount, setDidMount] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const [selected, setSelected] = useState(
      options.filter(({ selected: optionSelected }) => optionSelected)
    )
    const [selectOptions, setSelectOptions] = useState(options)
    const [filterValues, setFilterValues] = useState([])
    const [inputValue, setInputValue] = useState(``)

    const visibleOptions = selectOptions.filter(
      ({ label, dataFilter }) =>
        label.toLowerCase().includes(inputValue.toLowerCase()) &&
        (!filterValues.length ||
          dataFilter.some((value) => filterValues.includes(value)))
    )
    const inputLabel = populateInputLabel(selected, selectOptions, modelPlural)

    // Set didMount to control which effects run on initial render
    useEffect(() => {
      setDidMount(true)
    }, [])

    useEffect(() => {
      const handleDocClickFocus = (event) => {
        const refContainsEvent =
          containerRef.current && containerRef.current.contains(event.target)

        if (!refContainsEvent && expanded) {
          setExpanded(false)
        } else if (refContainsEvent && !expanded) {
          setExpanded(true)
        }
      }

      document.addEventListener("focusin", handleDocClickFocus, false)
      document.addEventListener("click", handleDocClickFocus, false)

      return () => {
        document.removeEventListener("focusin", handleDocClickFocus)
        document.removeEventListener("click", handleDocClickFocus)
      }
    }, [expanded, containerRef])

    // Clear the input whenever the menu is closed without a valid selection
    useEffect(() => {
      if (!didMount) return
      if (!expanded && selected.length === 0 && inputValue !== ``) {
        setInputValue(``)
      }
    }, [expanded])

    // Whenever focused index changes, update the document focus
    useEffect(() => {
      if (!didMount) return
      if (focusedIndex >= 0 && containerRef.current) {
        containerRef.current
          .querySelector(`li:nth-child(${focusedIndex + 1})`)
          .focus()
      } else if (containerRef.current) {
        containerRef.current.querySelector("input[role='combobox']").focus()
      }
    }, [focusedIndex])

    useEffect(() => {
      if (!didMount) return
      setFocusedIndex(-1)
    }, [inputValue])

    useEffect(() => {
      const selectedOptions = selected.filter(
        ($option) =>
          !filterValues.length ||
          $option.dataFilter.some((value) => filterValues.includes(value))
      )
      setSelected(selectedOptions)
    }, [filterValues])

    useEffect(() => {
      const selectedValue = selected.map(({ value }) => value)
      const updatedSelectOptions = selectOptions.map((option) => ({
        ...option,
        selected: selectedValue.includes(option.value),
      }))
      setSelectOptions(updatedSelectOptions)
    }, [selected])

    // Implement select-like keyboard controls
    const onKeydown = (e) => {
      if (["ArrowDown", "ArrowRight"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex < visibleOptions.length - 1) {
          setFocusedIndex(focusedIndex + 1)
        }
      } else if (["ArrowUp", "ArrowLeft"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex > -1) {
          setFocusedIndex(focusedIndex - 1)
        }
      } else if (e.code === "Enter") {
        e.preventDefault()
        if (focusedIndex >= 0) {
          toggleOption(visibleOptions[focusedIndex])
        }
      } else if (e.code === "Space") {
        if (focusedIndex >= 0) {
          e.preventDefault()
          toggleOption(visibleOptions[focusedIndex])
        }
      }
    }

    const updateSelected = (selectedOptions) => {
      setSelected(selectedOptions)
      const $form = containerRef.current.closest("form")
      $form?.dispatchEvent(
        new CustomEvent(`embed-autocomplete-${name}:updated`, {
          detail:
            selectedOptions.length < selectOptions.length
              ? selectedOptions.map(($option) => +$option.value)
              : [],
        })
      )
    }

    const toggleOption = (option) => {
      let selectedOptions = selected
      if (!selected.some(({ value }) => value === option.value)) {
        selectedOptions = addOptions(selected, [option])
      } else {
        selectedOptions = removeOptions(selected, [option])
      }
      updateSelected(selectedOptions)
    }

    const selectAll = () => {
      const selectedOptions = addOptions(selected, visibleOptions)
      updateSelected(selectedOptions)
    }

    const unselectAll = () => {
      const selectedOptions = removeOptions(selected, visibleOptions)
      updateSelected(selectedOptions)
    }

    return html`
      <div
        class="embed-autocomplete-field"
        @keydown=${onKeydown}
        ?ref=${ref(containerRef)}
      >
        <div class="embed-autocomplete-results">
          ${repeat(
            selected,
            ({ value }) => value,
            ({ value }, idx) => html` <input
              type="hidden"
              name=${name}
              id="id_${name}_${idx}"
              .value="${value}"
            />`
          )}
          <div
            class="embed-autocomplete-input relative flex flex-row items-center"
          >
            <input
              type="text"
              class="input"
              aria-expanded=${expanded.toString()}
              aria-owns="${name}__listbox"
              aria-autocomplete="list"
              autocomplete="off"
              placeholder=${placeholder || `Select ${modelPlural}`}
              aria-label=${placeholder}
              id="id_${name}"
              role="combobox"
              ?required=${required}
              value=${inputLabel}
              readonly
              @filter=${(e) => setFilterValues(e.detail)}
            />
          </div>
          <ul
            role="listbox"
            id="${name}__listbox"
            class="p-5 scroll-y-no-scrollbar ${expanded ? `block` : `hidden`}"
          >
            <div class="search-input relative mb-5">
              <input
                type="text"
                class="input"
                aria-expanded=${expanded.toString()}
                placeholder="Search"
                aria-label=${placeholder}
                role="combobox"
                .value=${inputValue}
                @input=${(e) => {
                  setInputValue(e.target.value || ``)
                }}
              />
              ${inputValue
                ? html`<button
                    type="button"
                    class="delete absolute top-4half right-4"
                    @keydown=${(e) => {
                      if (e.code === "Enter") {
                        e.preventDefault()
                        setInputValue(``)
                      }
                    }}
                    @click=${() => {
                      setInputValue(``)
                    }}
                  ></button>`
                : ``}
            </div>
            <div class="mb-4 flex justify-between">
              <div class="flex">
                <button
                  class="mr-8 text-blue hover:text-black"
                  type="button"
                  @click=${selectAll}
                >
                  Select all
                </button>
                <button
                  class="text-blue hover:text-black"
                  type="button"
                  @click=${unselectAll}
                >
                  Unselect all
                </button>
              </div>
              <span>Selected: ${selected.length}</span>
            </div>
            <div class="option-list">
              ${visibleOptions.length === 0
                ? html`<li class="m-0 p-4">No results found</li>`
                : ``}
              ${repeat(
                visibleOptions,
                ({ value }) => value,
                ({ label, value, selected, dataFilter }, index) => html`
                  <li
                    aria-selected=${(index === focusedIndex).toString()}
                    class="m-0 px-2 py-4 flex items-center cursor-pointer"
                    tabindex="-1"
                    role="option"
                    data-option-value=${value}
                    @click=${() => {
                      toggleOption({ label, value, dataFilter })
                    }}
                  >
                    <input type="checkbox" .checked=${selected} />
                    <span class="ml-5"> ${label} </span>
                  </li>
                `
              )}
            </div>
          </ul>
        </div>
      </div>
    `
  }
)

export default function renderEmbedAutocomplete(el) {
  const options = [...el.querySelectorAll("option")]
    .map((option) => ({
      label: option.innerText.trim(),
      value: option.value,
      selected: option.hasAttribute("selected"),
      dataFilter: JSON.parse(option.getAttribute("data-filter") || "[]"),
    }))
    .filter(({ value }) => !!value.trim())
  const mount = document.createElement("div")
  render(
    EmbedAutocompleteSelect({
      options,
      name: el.name,
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
      modelPlural: el.getAttribute("data-model-plural") || ``,
      required: el.hasAttribute("required"),
    }),
    mount
  )
  el.replaceWith(...mount.childNodes)
}
