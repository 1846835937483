import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useState } from "haunted"

const KeywordInput = virtual(({ name, placeholder, required, value }) => {
  const [keywords, setKeywords] = useState(value === "" ? [] : value.split(","))
  const [inputValue, setInputValue] = useState(``)

  const onAdd = () => {
    const keyword = inputValue.trim()
    if (keyword && !keywords.includes(keyword)) {
      setKeywords([...keywords, inputValue.trim()])
    }
    setInputValue(``)
  }

  const onRemove = (idx) => {
    const newKeywords = [...keywords]
    newKeywords.splice(idx, 1)
    setKeywords(newKeywords)
  }

  return html`<input
      class="input hidden"
      type="text"
      name="${name}"
      ?required=${required}
      id="id_${name}"
      .value=${keywords.join(",")}
    />
    <input
      class="input"
      type="text"
      placeholder=${placeholder}
      .value=${inputValue}
      @input=${(e) => {
        setInputValue(e.target.value.trim())
      }}
      @keydown=${(e) => {
        if (e.code === "Enter") {
          e.preventDefault()
          onAdd()
        }
      }}
    />
    <p class="help text-sm">Type & Press Enter to add keyword</p>
    <div class="-mt-5 flex flex-wrap">
      ${repeat(
        keywords,
        (keyword) => keyword,
        (keyword, idx) =>
          html`<div
            class="mr-5 my-2 p-2 pl-5 bg-gray-bg rounded-md flex items-center"
          >
            <span>${keyword}</span>
            <button
              type="button"
              class="delete delete-keyword p-6"
              aria-label="Remove ${keyword}"
              @click=${() => onRemove(idx)}
            ></button>
          </div>`
      )}
    </div>`
})

export default function renderKeywordInput(el) {
  const mount = document.createElement("div")
  render(
    KeywordInput({
      name: el.name,
      placeholder: el.placeholder,
      required: el.required,
      value: el.value,
    }),
    mount
  )
  el.replaceWith(...mount.childNodes)
}
