export const prefix = "cb"
export const iconName = "overflow"
export const width = 36
export const height = 22
export const ligatures = []
export const unicode = "f067"
export const svgPathData =
  "M10.4,9c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4S8,12.7,8,11.4S9.1,9,10.4,9z M18,9c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4S16.7,9,18,9z M25.6,9c1.3,0,2.4,1.1,2.4,2.4s-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4S24.3,9,25.6,9z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const cbOverflow = definition
