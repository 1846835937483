export default function tagBlocks(el) {
  let hasChecked = false
  el.querySelectorAll("input").forEach((input) => {
    if (!hasChecked && input.checked) {
      el.classList.add("tags-checked")
      hasChecked = true
    }
    input.addEventListener("change", () => {
      const tagsChecked = el.querySelectorAll("input:checked").length > 0
      el.classList.toggle("tags-checked", tagsChecked)
    })
  })
}
