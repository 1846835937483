import Cookies from "js-cookie"
import { BlockBlobClient } from "@azure/storage-blob"

const SIGNATURE_URL = "/azure-signature/"

class AzureUpload {
  constructor(onProgress, onFinish, onError) {
    const onDefault = () => console.log("event")
    this.onProgress = onProgress || onDefault
    this.onFinish = onFinish || onDefault
    this.onError = onError || onDefault
  }

  beginUpload(file, filename, uploadTo) {
    this.getSignature(SIGNATURE_URL, { name: filename, upload_to: uploadTo })
      .then((data) => this.uploadFile(file, data))
      .catch((err) => console.error(err))
  }

  uploadFile(file, data) {
    const blockBlobClient = new BlockBlobClient(
      `${data.url}/${data.container}/${data.blob_name}?${data.sas}`
    )
    const browserBlob = new Blob([file])
    blockBlobClient
      .uploadBrowserData(file, {
        maxSingleShotSize: 4 * 1024 * 1024,
        onProgress: ({ loadedBytes }) =>
          this.onProgress((loadedBytes / browserBlob.size) * 100),
        concurrency: 6,
      })
      .then((res, err) => {
        if (err) {
          this.onError(err)
        } else {
          this.onFinish(data.blob_name)
        }
      })
  }

  getSignature(url, data) {
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => (res.ok ? res.json() : Promise.reject(Error("error"))))
      .catch((err) => Promise.reject(Error(err.message)))
  }
}

export default AzureUpload
