import formDataEntries from "form-data-entries"
import { MENU_ACTION_KEYS } from "../constants"

export const fetchHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

export function formToObj(form) {
  const formDataObj = {}
  formDataEntries(form).forEach((pair) => {
    const [key, value] = pair
    if (value === null) return
    if (!(key in formDataObj)) {
      formDataObj[key] = value
    } else {
      const formVal = formDataObj[key]
      if (Array.isArray(formVal)) {
        formDataObj[key] = [...formVal, value]
      } else {
        formDataObj[key] = [formVal, value]
      }
    }
  })
  return formDataObj
}

/* eslint-disable */
// Debounce function from underscore
export const debounce = (func, wait, immediate) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
/* eslint-enable */

export const parseDate = (dtStr) => {
  const year = +dtStr.slice(0, 4)
  const month = +dtStr.slice(5, 7) - 1
  const day = +dtStr.slice(8, 10)
  const hour = +dtStr.slice(11, 13)
  const minute = +dtStr.slice(14, 16)
  return new Date(year, month, day, hour, minute)
}

export const addScript = (src, cb) => {
  const s = document.createElement("script")
  s.setAttribute("src", src)
  s.onload = cb
  document.body.appendChild(s)
}

export const facebookUrl = (url) =>
  `https://www.facebook.com/sharer/sharer.php?u=${window.encodeURIComponent(
    `${window.location.origin}${url}`
  )}`

export const twitterUrl = (url) =>
  `https://twitter.com/intent/tweet?text=${window.encodeURIComponent(
    `${window.location.origin}${url}`
  )}`

/**
 * Shuffles array
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  const arr = a
  for (let i = arr.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[arr[i], arr[j]] = [arr[j], arr[i]]
  }
  return arr
}

export function keyClickWrapper(event, onClick) {
  if (["Enter", "Space"].includes(event.code)) {
    if (event.code === "Space") event.preventDefault()
    event.stopPropagation()
    onClick(event)
  }
}

const FOCUSABLE = `button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]):not([data-focus-trap])`

export function focusOnFirstChild(el) {
  const focusable = el.querySelector(FOCUSABLE)
  if (focusable) {
    focusable.focus()
  }
}

// Trap focus inside a modal between two invisible, focusable nodes with the attribute
// "data-focus-trap" and tabindex="0"
// https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
export function onFocusModalTrap(modal) {
  if (modal.contains(document.activeElement)) {
    const focusable = modal.querySelector(FOCUSABLE)
    if ("focusTrap" in document.activeElement.dataset && focusable) {
      focusable.focus()
    }
  }
}

// Handle keyboard navigation on menus
export function onDropdownKeydown(event, el) {
  if (!MENU_ACTION_KEYS.includes(event.code)) return
  event.preventDefault()

  const docClickListener = (e) => {
    if (el.classList.contains("expanded") && !el.contains(e.target)) {
      el.classList.remove("expanded")
      el.querySelector("button").setAttribute("aria-expanded", "false")
      document.removeEventListener("click", docClickListener)
    }
  }

  const focusFirstItem = () => {
    for (const itemEl of el.querySelectorAll(FOCUSABLE)) {
      if (!itemEl.matches(`[aria-expanded]:first-child`)) {
        itemEl.focus()
        break
      }
    }
  }

  if (!el.classList.contains("expanded")) {
    if (["ArrowDown", "ArrowRight", "Space"].includes(event.code)) {
      el.classList.add("expanded")
      el.querySelector("button").setAttribute("aria-expanded", "true")
      focusFirstItem()
      document.addEventListener("click", docClickListener)
    }
  } else if (["ArrowDown", "ArrowRight"].includes(event.code)) {
    let nextSibling = document.activeElement.nextElementSibling
    while (nextSibling !== null && !nextSibling.matches(FOCUSABLE)) {
      nextSibling = nextSibling.nextElementSibling
    }
    if (nextSibling) {
      nextSibling.focus()
    } else {
      focusFirstItem()
    }
  } else if (["ArrowUp", "ArrowLeft"].includes(event.code)) {
    let prevSibling = document.activeElement.previousElementSibling
    while (prevSibling !== null && !prevSibling.matches(FOCUSABLE)) {
      prevSibling = prevSibling.previousElementSibling
    }
    if (prevSibling) {
      prevSibling.focus()
    } else {
      el.classList.remove("expanded")
      const button = el.querySelector("button")
      button.setAttribute("aria-expanded", "false")
      button.focus()
    }
  }
}

// Sanitize strings used in innerHTML calls to avoid potential XSS vulnerabilities
export function sanitizeText(text) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(text || "", "text/html")
  return doc.body.textContent
}
