import { html, render } from "lit-html"
import Modal from "../generic/modal"

export default function ImageModal(el) {
  const modalMount = document.getElementById("modal-mount")
  const dataEl = el.dataset.imageSrc ? el : el.parentNode
  const { imageSrc, imageTitle } = dataEl.dataset
  const onClose = () => {
    el.focus()
    render(html``, modalMount)
  }

  render(
    html`
      ${Modal({
        onClose,
        children: html`
          <div class="modal-content">
            <p class="image">
              <img src=${imageSrc} alt=${imageTitle} />
            </p>
          </div>
          <button
            @click=${onClose}
            class="modal-close delete"
            aria-label="close"
          ></button>
        `,
      })}
    `,
    modalMount
  )
}
