import { html, render } from "lit-html"
import Modal from "../generic/modal"

export default function ConfirmationModal(
  el,
  { header, message, confirmButton, onConfirm }
) {
  const modalMount = document.getElementById("modal-mount")
  const onClose = () => {
    el.focus()
    render(html``, modalMount)
  }

  render(
    html`
      ${Modal({
        onClose,
        children: html`
          <div
            id="confirmation-modal"
            class="modal-content rounded-md bg-white text-black"
          >
            <div
              data-section="header"
              class="p-7 font-serif text-xl border-b-px"
            >
              ${header}
            </div>
            <div data-section="message" class="px-7 pt-7 pb-8">${message}</div>
            <div class="px-7 py-6 border-t-px shadow-modal text-right">
              <button
                data-section="cancel-btn"
                class="py-4 hover:outline-none focus:outline-none"
                @click=${onClose}
              >
                Cancel
              </button>
              <button
                data-section="confirm-btn"
                class="ml-8 px-7 py-4 rounded text-white ${confirmButton.classes}"
                @click=${onConfirm}
              >
                ${confirmButton.label}
              </button>
            </div>
          </div>
        `,
      })}
    `,
    modalMount
  )
}
