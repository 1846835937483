import { keyClickWrapper, sanitizeText } from "../utils"

const TOAST_DURATION = 10 * 1000 // 10 seconds

export function removeToastMessage(messageEl) {
  messageEl.classList.add("disappearing")
  setTimeout(() => {
    const parent = messageEl.parentNode
    if (parent) {
      parent.removeChild(messageEl)
    }
  }, 1000)
}

export function addToastMessage(message, className = "", containerEl = null) {
  const container = containerEl || document.getElementById("toast-messages")
  const messageEl = document.createElement("div")
  messageEl.className = `toast notification ${className}`
  messageEl.innerHTML = `
    <button data-action="delete" class="delete" type="button" title="Dismiss notification"></button>
    ${sanitizeText(message)}
  `
  container.appendChild(messageEl)
  const deleteButton = messageEl.querySelector("button")
  deleteButton.addEventListener("click", () => removeToastMessage(messageEl))
  deleteButton.addEventListener("keydown", (e) =>
    keyClickWrapper(e, () => removeToastMessage(messageEl))
  )
  setTimeout(() => removeToastMessage(messageEl), TOAST_DURATION)
}

export default function Messages() {
  document
    .querySelectorAll(`#toast-messages > [data-component="toast"]`)
    .forEach((el) => {
      el.querySelector(`[data-action="delete"]`).addEventListener("click", () =>
        removeToastMessage(el)
      )
      setTimeout(() => removeToastMessage(el), TOAST_DURATION)
    })
}
