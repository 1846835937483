import { addToastMessage } from "../generic/messages"
import { ERROR_MESSAGE } from "../constants"

export default function FileInput(el) {
  const progressEl = el.querySelector("progress")
  let progress = 0

  const onProgress = (p) => {
    progress = p
    progressEl.style.display = progress ? "" : "none"
    progressEl.value = progress
  }

  const onFinish = (value) => {
    el.querySelector("input[name]").value = value
    el.querySelector(`[data-component="file-name"]`).innerText = value

    const $preview = el.querySelector(`[data-component="preview"]`)
    if ($preview) {
      const azureUploadDomain = $preview.dataset.azureDomain
      $preview.style.backgroundImage = `url(${azureUploadDomain}/${value})`
    }
  }

  const onError = (message) => {
    console.error(message)
    addToastMessage(ERROR_MESSAGE)
  }

  import(/* webpackChunkName: "azure" */ "../utils/azure").then((module) => {
    const AzureUpload = module.default
    el.querySelector('input[type="file"]').addEventListener(
      "change",
      (event) => {
        const { files } = event.target
        if (files.length) {
          onProgress(0)
          const file = files[0]
          const upload = new AzureUpload(onProgress, onFinish, onError)

          var uploadTo = ""
          const $uploadField = event.target.parentElement.querySelector(
            "input.input[type='hidden']"
          )
          if ($uploadField) {
            uploadTo = $uploadField.dataset.uploadTo
          }

          upload.beginUpload(file, file.name, uploadTo)
        }
      }
    )
  })
}
