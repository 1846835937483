import Cookies from "js-cookie"
import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useEffect, useState, useRef } from "haunted"
import { ref } from "../directives"

export const AutocompleteText = virtual(
  ({
    name,
    required,
    value,
    placeholder,
    endpoint,
    onResults = ({ results }) =>
      results.map(({ id, name, result }) => ({
        ...result,
        value: id.toString(),
        label: name,
      })),
    onSelect = () => {},
    onClear = () => {},
    noResultsText = "No results found",
    form = null,
  }) => {
    const containerRef = useRef(null)
    const [didMount, setDidMount] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const [inputValue, setInputValue] = useState(value)
    const [options, setOptions] = useState([])

    const isSameOrigin =
      new URL(endpoint, window.location.origin).hostname ===
      window.location.hostname

    // Set didMount to control which effects run on initial render
    useEffect(() => {
      setDidMount(true)
    }, [])

    useEffect(() => {
      const handleDocClickFocus = (event) => {
        const refContainsEvent =
          containerRef.current && containerRef.current.contains(event.target)

        if (!refContainsEvent && expanded) {
          setExpanded(false)
        } else if (refContainsEvent && !expanded) {
          setExpanded(true)
        }
      }

      document.addEventListener("focusin", handleDocClickFocus, false)
      document.addEventListener("click", handleDocClickFocus, false)

      return () => {
        document.removeEventListener("focusin", handleDocClickFocus)
        document.removeEventListener("click", handleDocClickFocus)
      }
    }, [expanded, containerRef])

    // Remove focused index whenever options change
    useEffect(() => {
      if (focusedIndex === -1) return
      setFocusedIndex(-1)
    }, [options])

    // Whenever focused index changes, update the document focus
    useEffect(() => {
      if (!didMount) return
      if (focusedIndex >= 0 && containerRef.current) {
        containerRef.current
          .querySelector(`li:nth-child(${focusedIndex + 1})`)
          .focus()
      } else if (containerRef.current) {
        containerRef.current.querySelector("input[role='combobox']").focus()
      }
    }, [focusedIndex])

    const updateResults = (value) => {
      if (!value || !value.trim()) {
        setOptions([])
        setExpanded(false)
        onClear()
        return
      }
      fetch(`${endpoint}${value}`, {
        method: "GET",
        ...(isSameOrigin
          ? {
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRFToken": Cookies.get("csrftoken"),
              },
            }
          : {}),
      })
        .then((res) => res.json())
        .then((res) => setOptions(onResults(res)))
        .catch(console.error)
    }

    // Update the results from an endpoint if supplied
    useEffect(() => {
      if (!didMount) return
      // Regular debounce doesn't work here, need to use callback
      const handler = setTimeout(() => updateResults(inputValue), 500)
      return () => clearTimeout(handler)
    }, [inputValue])

    // Implement select-like keyboard controls
    const onKeydown = (e) => {
      if (["ArrowDown", "ArrowRight"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex < options.length - 1) {
          setFocusedIndex(focusedIndex + 1)
        }
      } else if (["ArrowUp", "ArrowLeft"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex > -1) {
          setFocusedIndex(focusedIndex - 1)
        }
      } else if (e.code === "Enter") {
        e.preventDefault()
        if (focusedIndex >= 0) {
          updateSelected(options[focusedIndex])
        }
      } else if (e.code === "Space") {
        if (focusedIndex >= 0) {
          e.preventDefault()
          updateSelected(options[focusedIndex])
        }
      }
    }

    // Helper function for the multiple tasks that need to be completed on update
    const updateSelected = (option) => {
      setInputValue(option.value)
      setFocusedIndex(-1)
      setExpanded(false)
      onSelect(option)
    }

    return html`
      <div
        class="autocomplete-field"
        @keydown=${onKeydown}
        ?ref=${ref(containerRef)}
      >
        <div class="autocomplete-results">
          <div class="autocomplete-input relative flex flex-row items-center">
            <input
              type="text"
              class="input"
              aria-expanded=${expanded.toString()}
              aria-owns="${name}__listbox"
              aria-autocomplete="list"
              autocomplete="off"
              name=${name}
              placeholder=${placeholder}
              aria-label=${placeholder}
              id="id_${name}"
              role="combobox"
              ?required=${required}
              .value=${inputValue}
              @input=${(e) => setInputValue(e.target.value || ``)}
              ?form=${!!form}
            />
            ${inputValue
              ? html`<button
                  type="button"
                  class="delete absolute right-4"
                  @keydown=${(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault()
                      setInputValue(``)
                    }
                  }}
                  @click=${() => setInputValue(``)}
                ></button>`
              : ``}
          </div>
          ${options.length > 0 || noResultsText
            ? html`<ul
                role="listbox"
                id="${name}__listbox"
                class="scroll-y-no-scrollbar ${expanded ? `block` : `hidden`}"
              >
                ${options.length === 0
                  ? html`<li class="m-0 p-4">${noResultsText}</li>`
                  : ``}
                ${repeat(
                  options,
                  ({ value }) => value,
                  ({ label, ...option }, index) => html`
                    <li
                      aria-selected=${(index === focusedIndex).toString()}
                      class="m-0 p-4 cursor-pointer"
                      tabindex="-1"
                      role="option"
                      @click=${(e) => {
                        e.preventDefault()
                        updateSelected({ label, ...option })
                      }}
                    >
                      ${label}
                    </li>
                  `
                )}
              </ul>`
            : ``}
        </div>
      </div>
    `
  }
)

export default function renderAutocompleteText(el) {
  const mount = el.parentNode
  render(
    AutocompleteText({
      name: el.name,
      value: el.value || ``,
      required: el.hasAttribute("required"),
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
      endpoint: el.dataset["endpoint"],
    }),
    mount
  )
}
