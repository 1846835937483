import { html } from "lit-html"
import { parseDate } from "../utils"

const DateTime = ({ start_date, start_time, shortened_timezone, canceled }) => {
  const startStr = start_time ? `${start_date} ${start_time}` : start_date
  const start = parseDate(startStr)
  const startDate = start.toLocaleDateString("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  })
  const startTime = start
    .toLocaleTimeString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
    })
    .replace(":00", "")
    .replace("AM", "a.m.")
    .replace("PM", "p.m.")
  const invalidTime = start.getHours() === 0
  return html`
    <p class="${canceled ? `line-through` : ``}">${startDate}</p>
    <p class="${invalidTime ? `italic` : ``} ${canceled ? `line-through` : ``}">
      ${invalidTime
        ? "See details for time"
        : `${startTime} ${shortened_timezone || ""}`}
    </p>
  `
}

export default DateTime
