import { html } from "lit-html"
import { ifDefined } from "lit-html/directives/if-defined"
import { virtual, useEffect, useRef } from "haunted"
import { ref } from "../directives"
import { focusOnFirstChild, onFocusModalTrap } from "../utils"

const Modal = virtual(
  ({
    children,
    onClose,
    labelledBy,
    describedBy,
    className = ``,
    modalBackgroundClass = "modal-background",
  }) => {
    const modalRef = useRef(null)

    useEffect(() => {
      if (modalRef.current) {
        focusOnFirstChild(modalRef.current)
      }

      const handleDocClick = (event) => {
        if ("modal" in event.target.dataset) {
          onClose()
          removeListeners()
        }
      }

      const onKeydown = (event) => {
        if (event.key === "Escape") {
          onClose()
          removeListeners()
        }
      }

      const onFocus = () => onFocusModalTrap(modalRef.current)

      document.addEventListener("click", handleDocClick, false)
      document.addEventListener("keydown", onKeydown, false)
      document.addEventListener("focus", onFocus, true)

      const removeListeners = () => {
        document.removeEventListener("click", handleDocClick)
        document.removeEventListener("keydown", onKeydown)
        document.removeEventListener("focus", onFocus)
      }

      return removeListeners
    }, [modalRef])

    return html`<div
      class="modal active ${className}"
      role="dialog"
      data-modal
      aria-modal="true"
      aria-labelledby=${ifDefined(labelledBy)}
      aria-describedby=${ifDefined(describedBy)}
      ?ref=${ref(modalRef)}
    >
      <div data-modal class="${modalBackgroundClass}"></div>
      <div data-focus-trap tabindex="0"></div>
      ${children}
      <div data-focus-trap tabindex="0"></div>
    </div>`
  }
)

export default Modal
