import { html, render } from "lit-html"

import { repeat } from "lit-html/directives/repeat"
import { virtual, useState } from "haunted"

const TIME_RANGE = [6, 22]

const getHourDisplay = (hour) =>
  `${hour <= 12 ? hour : hour - 12}:00${hour < 12 ? "am" : "pm"}`

export const TimeRangeInput = virtual(({ name, id, start, end }) => {
  const [expanded, setExpanded] = useState(false)
  const [timeStart, setTimeStart] = useState(start)
  const [timeEnd, setTimeEnd] = useState(end)

  const hours = Array.from(
    { length: TIME_RANGE[1] - TIME_RANGE[0] + 1 },
    (v, k) => k + TIME_RANGE[0]
  )

  const updateStart = (e) => {
    const value = +e.currentTarget.value
    setTimeStart(value)
    setTimeEnd(Math.max(value, timeEnd))
  }

  const updateEnd = (e) => {
    const value = +e.currentTarget.value
    setTimeEnd(value)
    setTimeStart(Math.min(value, timeStart))
  }

  const inputValue =
    timeStart === TIME_RANGE[0] && timeEnd === TIME_RANGE[1]
      ? ``
      : `${timeStart}:00,${timeEnd}:00`

  // TODO: Replace with aria setup? https://www.w3.org/TR/wai-aria-practices-1.2/examples/slider/slider-2.html
  // Also fix that values are read as integers, not times
  // Two range inputs with only the handles/thumbs allowing pointer events
  return html`
    <button
      class="button w-full border-px border-gray-border text-blue min-h-40 bg-gray-bg focus:outline-none hover:border-blue active:border-blue focus:border-blue"
      type="button"
      aria-label="Time range"
      aria-controls="time-range-dialog"
      aria-expanded=${expanded.toString()}
      id="time-range-button"
      ?data-has-value=${inputValue !== ``}
      @click=${() => setExpanded(!expanded)}
    >
      ${getHourDisplay(timeStart)} — ${getHourDisplay(timeEnd)}
    </button>
    <input id=${id} name=${name} type="hidden" .value=${inputValue} />
    <div
      id="time-range-dialog"
      class="time-range-container absolute top-full left-0 right-0 pb-1 z-1 overflow-hidden ${expanded
        ? ``
        : `hidden`}"
      aria-labelledby="time-range-button"
      role="dialog"
    >
      <input
        type="range"
        class="start"
        min="${TIME_RANGE[0]}"
        max="${TIME_RANGE[1]}"
        aria-label="Start time"
        .value=${timeStart}
        @change=${updateStart}
        @input=${updateStart}
        step="1"
      />
      <input
        type="range"
        class="end"
        min="${TIME_RANGE[0]}"
        max="${TIME_RANGE[1]}"
        aria-label="End time"
        .value=${timeEnd}
        @change=${updateEnd}
        @input=${updateEnd}
        step="1"
      />
      <div
        class="time-range-background absolute top-0 left-0 bottom-0 right-0 h-full w-full text-center bg-gray-bg rounded-md border-px border-gray-border pointer-events-none"
        aria-hidden="true"
      >
        ${repeat(
          hours,
          (hour) => hour,
          (hour) =>
            html`<div
              class="item ${hour >= timeStart && hour <= timeEnd
                ? `selected`
                : ``} ${hour === timeStart ? `start` : ``} ${hour === timeEnd
                ? `end`
                : ``}"
            >
              ${getHourDisplay(hour)}
            </div>`
        )}
      </div>
    </div>
  `
})

export default function renderTimeRange(el) {
  const input = el.querySelector(`input[type='hidden']`)
  const inputValue = input.value
  const [start, end] = inputValue.trim()
    ? inputValue.split(",").map((val) => +val.split(":")[0])
    : TIME_RANGE
  render(TimeRangeInput({ el, name: input.name, id: input.id, start, end }), el)
}
