export const prefix = "cb"
export const iconName = "arrow-up-from-square"
export const width = 26
export const height = 34
export const ligatures = []
export const unicode = "e09c"
export const svgPathData =
  "M 18.3 8.7 L 13 3.4 l -5.3 5.3 l -1.4 -1.4 L 13 0.6 l 6.7 6.7 z M 12 2 h 2 v 21 h -2 z M 18.3 35 H 3 c -1.7 0 -3 -1.3 -3 -3 V 14 c 0 -1.7 1.3 -3 3 -3 h 7 v 2 h -7 c -0.6 0 -1 0.4 -1 1 v 18 c 0 0.6 0.4 1 1 1 h 20 c 0.6 0 1 -0.4 1 -1 V 14 c 0 -0.6 -0.4 -1 -1 -1 h -7 v -2 h 7 c 1.7 0 3 1.3 3 3 v 18 c 0 1.7 -1.3 3 -3 3 z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const cbArrowUpFromSquare = definition
