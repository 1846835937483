export const prefix = "cb"
export const iconName = "share-nodes"
export const width = 15
export const height = 16.6
export const ligatures = []
export const unicode = "f1e0"
export const svgPathData =
  "M13 12.4003C12.3667 12.4003 11.8 12.6503 11.3667 13.042L5.425 9.58366C5.46667 9.39199 5.5 9.20033 5.5 9.00033C5.5 8.80033 5.46667 8.60866 5.425 8.41699L11.3 4.99199C11.75 5.40866 12.3417 5.66699 13 5.66699C14.3833 5.66699 15.5 4.55033 15.5 3.16699C15.5 1.78366 14.3833 0.666992 13 0.666992C11.6167 0.666992 10.5 1.78366 10.5 3.16699C10.5 3.36699 10.5333 3.55866 10.575 3.75033L4.7 7.17533C4.25 6.75866 3.65833 6.50033 3 6.50033C1.61667 6.50033 0.5 7.61699 0.5 9.00033C0.5 10.3837 1.61667 11.5003 3 11.5003C3.65833 11.5003 4.25 11.242 4.7 10.8253L10.6333 14.292C10.5917 14.467 10.5667 14.6503 10.5667 14.8337C10.5667 16.1753 11.6583 17.267 13 17.267C14.3417 17.267 15.4333 16.1753 15.4333 14.8337C15.4333 13.492 14.3417 12.4003 13 12.4003Z"

export const definition = {
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
}

export const cbShareNodes = definition
