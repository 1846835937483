const ConditionalInput = (el) => {
  const conditionInput = document.querySelector(
    `[name="${el.dataset.conditionInput}"]`
  )
  const conditionValues = el.dataset.conditionValues.split(",")

  const updateConditionalInput = () => {
    el.toggleAttribute(
      "disabled",
      !conditionValues.includes(conditionInput.value)
    )
  }

  updateConditionalInput()
  conditionInput.addEventListener("change", updateConditionalInput)
}

export default ConditionalInput
