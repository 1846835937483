import Cookies from "js-cookie"
import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useEffect, useState, useRef } from "haunted"
import { ref } from "../directives"

const MAX_RESULTS = 100

const MultipleSelectValues = ({ selected, onDelete }) => html`
  ${repeat(
    selected,
    ({ value }) => value,
    ({ value, label }) =>
      html`<span
        class="inline-flex flex-row items-center bg-gray-bg border-px border-gray-border rounded-md px-4 py-2 mr-1 mb-1"
        >${label}
        <button
          @click=${() => onDelete(value)}
          @keydown=${(e) => {
            if (e.code === "Enter") onDelete(value)
          }}
          type="button"
          class="delete ml-2"
          aria-label="Remove ${label}"
        ></button
      ></span>`
  )}
`

export const AutocompleteSelect = virtual(
  ({ name, required, placeholder, options, endpoint, multiple, labelFn }) => {
    const containerRef = useRef(null)
    const [didMount, setDidMount] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [focusedIndex, setFocusedIndex] = useState(-1)
    const [selected, setSelected] = useState(
      options.filter(({ selected: optionSelected }) => optionSelected)
    )
    const [inputValue, setInputValue] = useState(
      !multiple && selected.length > 0 ? selected[0].label : ``
    )
    const [selectOptions, setSelectOptions] = useState(options)
    const visibleOptions = selectOptions
      .filter(
        ({ label, value: optionValue }) =>
          (!!endpoint ||
            label.toLowerCase().includes(inputValue.toLowerCase())) &&
          !selected.map(({ value }) => value).includes(optionValue)
      )
      .slice(0, MAX_RESULTS)

    // Set didMount to control which effects run on initial render
    useEffect(() => {
      setDidMount(true)
    }, [])

    useEffect(() => {
      const handleDocClickFocus = (event) => {
        const refContainsEvent =
          containerRef.current && containerRef.current.contains(event.target)

        if (!refContainsEvent && expanded) {
          setExpanded(false)
        } else if (refContainsEvent && !expanded) {
          setExpanded(true)
        }
      }

      document.addEventListener("focusin", handleDocClickFocus, false)
      document.addEventListener("click", handleDocClickFocus, false)

      return () => {
        document.removeEventListener("focusin", handleDocClickFocus)
        document.removeEventListener("click", handleDocClickFocus)
      }
    }, [expanded, containerRef])

    // Clear the input whenever the menu is closed without a valid selection
    useEffect(() => {
      if (!didMount) return
      if (!expanded && selected.length === 0 && inputValue !== ``) {
        setInputValue(``)
      }
    }, [expanded])

    // Remove focused index whenever options change
    useEffect(() => {
      if (focusedIndex === -1) return
      setFocusedIndex(-1)
    }, [selectOptions])

    // Whenever focused index changes, update the document focus
    useEffect(() => {
      if (!didMount) return
      if (focusedIndex >= 0 && containerRef.current) {
        containerRef.current
          .querySelector(`li:nth-child(${focusedIndex + 1})`)
          .focus()
      } else if (containerRef.current) {
        containerRef.current.querySelector("input[role='combobox']").focus()
      }
    }, [focusedIndex])

    const csrftoken = Cookies.get("csrftoken")
    const updateResults = (value) => {
      if (!value || !value.trim()) {
        setSelectOptions([])
        setExpanded(false)
        return
      }
      fetch(`${endpoint}${value}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "X-CSRFToken": csrftoken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(({ results }) => {
          setSelectOptions(
            results.map(({ id, name }) => ({
              value: id.toString(),
              label: name,
            }))
          )
        })
        .catch(console.error)
    }

    // Update the results from an endpoint if supplied
    useEffect(() => {
      if (!didMount) return
      if (endpoint) {
        const handler = setTimeout(() => updateResults(inputValue), 500)
        return () => clearTimeout(handler)
      }
    }, [inputValue])

    // Implement select-like keyboard controls
    const onKeydown = (e) => {
      if (["ArrowDown", "ArrowRight"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex < visibleOptions.length - 1) {
          setFocusedIndex(focusedIndex + 1)
        }
      } else if (["ArrowUp", "ArrowLeft"].includes(e.code)) {
        e.preventDefault()
        if (focusedIndex > -1) {
          setFocusedIndex(focusedIndex - 1)
        }
      } else if (e.code === "Enter") {
        e.preventDefault()
        if (focusedIndex >= 0) {
          updateSelected(visibleOptions[focusedIndex])
        }
      } else if (e.code === "Space") {
        if (focusedIndex >= 0) {
          e.preventDefault()
          updateSelected(visibleOptions[focusedIndex])
        }
      }
    }

    // Handler for deleting a multiple select selected value
    const onRemoveOption = (removeValue) => {
      setSelected(selected.filter(({ value }) => value !== removeValue))
    }

    // Helper function for the multiple tasks that need to be completed on update
    const updateSelected = (option) => {
      setSelected(multiple ? [...selected, option] : [option])
      if (!multiple) {
        setInputValue(option.label)
        setExpanded(false)

        document.dispatchEvent(
          new CustomEvent(`autocomplete-${name}:updated`, {
            detail: option,
          })
        )
      }
      setFocusedIndex(-1)
    }

    const removeSelected = () => {
      setSelected([])
      setInputValue(``)

      document.dispatchEvent(
        new CustomEvent(`autocomplete-${name}:removed`, {})
      )
    }

    return html`
      <div
        class="autocomplete-field"
        @keydown=${onKeydown}
        ?ref=${ref(containerRef)}
      >
        ${multiple
          ? html`<div class="autocomplete-selections">
              ${MultipleSelectValues({
                selected,
                onDelete: onRemoveOption,
              })}
            </div>`
          : ``}
        <div class="autocomplete-results">
          ${repeat(
            selected,
            ({ value }) => value,
            ({ value }, idx) => html` <input
              type="hidden"
              name=${name}
              id="id_${name}_${idx}"
              .value="${value}"
            />`
          )}
          <div class="autocomplete-input relative flex flex-row items-center">
            <input
              type="text"
              class="input"
              aria-expanded=${expanded.toString()}
              aria-owns="${name}__listbox"
              aria-autocomplete="list"
              autocomplete="off"
              placeholder=${placeholder}
              aria-label=${placeholder}
              id="id_${name}"
              role="combobox"
              ?required=${required && !multiple}
              .value=${inputValue}
              @input=${(e) => {
                // Set display value, unset interval value if not a multiple select
                setInputValue(e.target.value || ``)
                if (!multiple) setSelected([])
              }}
              @server-input=${(e) => {
                const option = selectOptions.find(
                  (opt) => opt.value === e.detail
                )
                if (option) {
                  updateSelected(option)
                }
              }}
            />
            ${inputValue
              ? html`<button
                  type="button"
                  class="delete absolute right-4"
                  @keydown=${(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault()
                      removeSelected()
                    }
                  }}
                  @click=${removeSelected}
                ></button>`
              : ``}
          </div>
          <ul
            role="listbox"
            id="${name}__listbox"
            class="scroll-y-no-scrollbar ${expanded ? `block` : `hidden`}"
          >
            ${visibleOptions.length === 0
              ? html`<li class="m-0 p-4">No results found</li>`
              : ``}
            ${repeat(
              visibleOptions,
              ({ value }) => value,
              ({ label, value, dataset }, index) => html`
                <li
                  aria-selected=${(index === focusedIndex).toString()}
                  class="m-0 p-4 cursor-pointer"
                  tabindex="-1"
                  role="option"
                  data-option-value=${value}
                  @click=${(e) => {
                    e.preventDefault()
                    updateSelected({ label, value, dataset })
                  }}
                >
                  ${labelFn ? labelFn({ label, value }) : label}
                </li>
              `
            )}
          </ul>
        </div>
      </div>
    `
  }
)

export default function renderAutocomplete(el) {
  const options = [...el.querySelectorAll("option")]
    .map((option) => ({
      label: option.innerText.trim(),
      value: option.value,
      dataset: option.dataset,
      selected: option.hasAttribute("selected"),
    }))
    .filter(({ value }) => !!value.trim())
  const mount = document.createElement("div")
  render(
    AutocompleteSelect({
      options,
      name: el.name,
      placeholder:
        el.getAttribute("placeholder") || el.getAttribute("aria-label") || ``,
      required: el.hasAttribute("required"),
      multiple: el.hasAttribute("multiple"),
      endpoint: el.dataset["endpoint"],
    }),
    mount
  )
  el.replaceWith(...mount.childNodes)
}
