import { html, render } from "lit-html"
import { repeat } from "lit-html/directives/repeat"
import { virtual, useState } from "haunted"

import Modal from "../generic/modal"

const copyShareLink = (shareUrl) => {
  navigator.clipboard.writeText(shareUrl)
  const $imageBanner = document.querySelector(".modal-content .image-banner")
  if ($imageBanner) {
    $imageBanner.classList.remove("hidden")
    $imageBanner.textContent = "Copied to clipboard"
    setTimeout(() => {
      $imageBanner.classList.add("hidden")
    }, 2000)
  }
}

export const ImageGallery = virtual(({ images, selectedIdx, removeModal }) => {
  const [activeIdx, setActiveIdx] = useState(selectedIdx)
  const {
    src: activeSrc,
    name: activeName,
    creator,
    description,
    date,
    downloadUrl,
    shareUrl,
  } = images[activeIdx]

  return html`${Modal({
    className: `image-gallery-modal`,
    onClose: removeModal,
    children: html`
      <div class="modal-content px-6 md:px-10 w-full h-screen">
        <button
          @click=${removeModal}
          class="h-12half sm:text-lg text-white font-bold"
          aria-label="close"
        >
          &#x2190; Back
        </button>
        <div style="height: calc(100vh - 60px)">
          <div class="w-full" style="height: calc(100% - 100px)">
            <div class="w-full h-3/4 relative">
              <div
                class="relative mx-auto h-full"
                style="max-width: min(100%, 1200px);"
              >
                <img
                  class="w-full h-full object-contain"
                  src=${activeSrc}
                  alt=${activeName}
                />
                <div
                  class="image-banner hidden absolute bottom-0 w-full bg-black opacity-90 px-6 py-5 text-center text-white"
                ></div>
              </div>
              <button
                @click=${() => setActiveIdx(Math.max(0, activeIdx - 1))}
                class="
                    ${images.length === 1 ? `hidden` : ``}
                    ${activeIdx === 0 ? `bg-gray cursor-default` : ``}
                    absolute top-1/2 left-0 lg:left-16 w-10 h-10 rounded-md bg-blue flex justify-center items-center text-xl text-white
                  "
                aria-label="previous"
              >
                <i class="fas fa-arrow-left"></i>
              </button>
              <button
                @click=${() =>
                  setActiveIdx(Math.min(images.length - 1, activeIdx + 1))}
                class="
                    ${images.length === 1 ? `hidden` : ``}
                    ${activeIdx === images.length - 1
                  ? `bg-gray cursor-default`
                  : ``}
                    absolute top-1/2 right-0 lg:right-16 w-10 h-10 rounded-md bg-blue flex justify-center items-center text-xl text-white
                  "
                aria-label="next"
              >
                <i class="fas fa-arrow-right"></i>
              </button>
            </div>
            <div
              class="mx-auto w-full h-1/4 pt-7 scroll-y-no-scrollbar sm:flex flex-row-reverse justify-between text-white"
              style="max-width: min(100%, 1200px);"
            >
              <div class="mb-4 sm:ml-4 shrink-0">
                <a
                  class="inline-block ml-3 leading-[36px] px-5 rounded-md bg-white text-blue font-bold"
                  href=${downloadUrl}
                >
                  <i class="fas fa-arrow-down-to-line"></i>
                  <span class="ml-2">Download</span>
                </a>
                <button
                  class="ml-3 h-9half px-5 rounded-md bg-white text-blue font-bold hover:text-black"
                  @click=${(e) => {
                    e.preventDefault()
                    copyShareLink(shareUrl)
                  }}
                >
                  <i class="cb fa-share-nodes"></i>
                  <span class="ml-2">Share</span>
                </button>
              </div>
              <div>
                <p class="mb-4 sm:text-lg font-bold">${activeName}</p>
                ${description
                  ? html`<p class="mb-4 text-sm">${description}</p>`
                  : ``}
                <p class="text-tiny">
                  ${creator ? html`<span>By ${creator}</span>` : ``}
                  ${date ? html`<span>&#8901; ${date}</span>` : ``}
                </p>
              </div>
            </div>
            <div
              class="mx-auto w-full pt-4 md:pb-7 h-14half flex flex-row flex-nowrap scroll-x-no-scrollbar"
              style="max-width: min(100%, 1200px);"
            >
              ${repeat(
                images,
                ({ id }) => id,
                ({ name, thumbnail }, idx) => html`
                  <div class="shrink-0 mr-5 w-14">
                    <button
                      type="button"
                      class="link-text-color border-2 rounded ${activeIdx ===
                      idx
                        ? `border-yellow`
                        : `border-transparent`}"
                      @click=${() => setActiveIdx(idx)}
                    >
                      <span class="thumbnail mb-0 h-12half">
                        <img alt=${name} src=${thumbnail} />
                      </span>
                    </button>
                  </div>
                `
              )}
            </div>
          </div>
        </div>
      </div>
    `,
  })}`
})

export function renderImageGallery($el) {
  const modalMount = document.getElementById("modal-mount")

  const removeModal = () => {
    $el.querySelector("button").focus()
    render(html``, modalMount)
  }

  const $images = $el.querySelectorAll(`[data-component="gallery-image"]`)
  const imageData = [...$images].map(($imageEl) => {
    const shareLink = $imageEl.dataset.share
    const shareUrl = `${window.location.origin}${window.location.pathname}${shareLink}`

    return {
      name: $imageEl.dataset.name,
      id: $imageEl.dataset.id,
      src: $imageEl.dataset.src,
      thumbnail: $imageEl.dataset.thumbnail,
      creator: $imageEl.dataset.creator || null,
      description: $imageEl.dataset.description || null,
      date: $imageEl.dataset.date || null,
      downloadUrl: $imageEl.dataset.downloadUrl || null,
      shareUrl: shareUrl,
    }
  })

  $images.forEach(($imageEl, idx) => {
    $imageEl.addEventListener("click", () => {
      render(
        ImageGallery({
          images: imageData,
          selectedIdx: idx,
          removeModal,
        }),
        modalMount
      )
    })
  })
}
