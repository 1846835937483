import { html } from "lit-html"

const inactiveCls = "bg-transparent text-blue hover:text-black focus:text-black"
const activeCls = "bg-yellow text-black hover:text-red focus:text-red"

const Toggle = ({ defaultLabel, altLabel, altSort, onToggle }) => html`
  ${defaultLabel && altLabel
    ? html`
        <div
          data-section="time-toggle"
          class="mb-6 flex items-center justify-center"
        >
          <div
            class="w-300 min-w-300 flex flex-row flex-nowrap rounded-md border-px border-gray-border bg-gray-bg hover:bg-gray-400 focus:bg-gray-400"
          >
            <button
              class="flex flex-1 items-center justify-center px-6 py-4 rounded-md ${altSort
                ? inactiveCls
                : activeCls}"
              type="button"
              aria-pressed="${!altSort}"
              @click=${() => (altSort ? onToggle(false) : null)}
            >
              ${defaultLabel}
            </button>
            <button
              class="flex flex-1 items-center justify-center px-6 py-4 rounded-md ${altSort
                ? activeCls
                : inactiveCls}"
              aria-pressed="${altSort}"
              type="button"
              @click=${() => (altSort ? null : onToggle(true))}
            >
              ${altLabel}
            </button>
          </div>
        </div>
      `
    : ``}
`

export default Toggle
