import { html } from "lit-html"
import { repeat } from "lit-html/directives/repeat"

const TooltipIcons = (items) => html`<div class="tooltip-icons">
  ${repeat(
    items,
    ({ label }) => label,
    ({ label, icon }) => html`<span class="tooltip-icon">
      <i class=${icon}></i>
      <span class="tooltip-icon-tooltip">${label}</span>
    </span>`
  )}
</div>`

export default TooltipIcons
